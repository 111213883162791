@use "../../core/styles/variables" as vars;

.header {
    &__button {
        margin: 0.25rem;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        right: 0;
        height: 3rem;
        width: 3rem;
        font-size: 1.875rem;
        color: vars.$link-text-color;
    }

    .nav-links {
        position: absolute;
        margin-top: -200px;
        transition: margin-top 500ms ease-in-out;

        &__links {
            padding: 1.25rem;
        }
    }
}

@media screen and (min-width: vars.$break-small) {
    .header {
        &__button {
            display: none;
        }

        .nav-links {
            right: 0;
            margin-top: 0;
            width: 29.375rem;
        }
    }
}

//======== End of @media screen and (min-width: vars.$break-small) ========/

@media screen and (min-width: vars.$content-max-width) {
    .header .nav-links {
        right: auto;
        margin-left: 59.5%;
    }
}
