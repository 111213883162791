@use "../../../core/styles/variables.scss" as vars;

.contact {
    &__content {
        max-width: vars.$break-large;
        margin: 0 auto 0.5rem;
        text-align: justify;
    }

    &__links {
        text-align: center;

        svg {
            margin-right: 0.3125rem;
        }

        a {
            color: vars.$accent-color;
        }
    }
}
