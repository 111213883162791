// Responsive Design
$break-small: 34rem;
$break-large: 43.75rem;

// Fonts
$serif-font-stack: Garamond, Baskerville, "Baskerville Old Face", "Hoefler Text",
    "Times New Roman", serif;
$mono-font-stack: monaco, "Andale Mono", monospace;

// Colors
$accent-color: #22bcf0;
$dark-background-color: #282c34;
$light-body-color: #fafafa;
$light2-body-color: #e0e8f0;
$lighter-dark-background-color: #3d4350;
$nav-color: #6e6e6e;

// Font Colors
$main-text-color: #999;
$link-text-color: #abb2bf;
$dark-text-color: #302f2f;
$light-text-color: #fafafa;
$css-text-color: #98c379;

// Shadow Colors
$light-bg-shadow-color: #aaa;
$dark-bg-shadow-color: #000;

// Other
$content-max-width: 73.8125rem;
