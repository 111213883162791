@use "../../../core/styles/variables.scss" as vars;

.skills {
    &__list {
        max-width: 62rem;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
    }

    &__category {
        background-color: vars.$light-body-color;
        box-shadow: 0.0625rem 0.125rem 0.125rem vars.$light-bg-shadow-color;
        padding: 1rem;
        text-align: center;
        transition: 0.25s ease;

        &:hover {
            box-shadow: 0.25rem 0.5rem 0.625rem vars.$light-bg-shadow-color;
            transition: 0.25s ease;
            scale: 1.005;
        }
    }

    &__items-container {
        display: flex;
        margin: 1rem auto 0;
        flex-wrap: wrap;
        justify-content: space-around;
        max-width: 18rem;
        gap: 2rem;
    }

    &__item {
        flex: 1 1 0px;
    }

    &__img {
        height: 4.75rem;
    }
}

@media screen and (min-width: vars.$break-small) {
    .skills {
        &__list {
            flex-flow: row wrap;
        }

        &__category {
            flex-grow: 1;
        }

        &__category-title {
            text-align: left;
        }

        &__items-container {
            max-width: none;
        }
    }
}
