@use "../../../core/styles/variables.scss" as vars;

.introduction {
    height: 100vh;
    margin: auto;
    padding: 0;
    text-align: center;

    &__content {
        display: inline-block;
        margin-top: 40vh;
    }

    &__logo {
        float: left;
        margin: 0 0.5rem 0 0;
        width: 18vw;
    }

    &__text {
        display: inline-block;
        text-align: left;
    }

    &__color-white {
        color: vars.$light-text-color;
    }

    &__title {
        margin-left: 4.5vw;
    }

    &__color-green {
        color: vars.$css-text-color;
    }

    &__teaser {
        margin-top: 10vh;
    }

    &__arrow {
        width: 2rem;
        cursor: pointer;
    }
}

@media screen and (min-width: vars.$break-small) {
    .introduction {
        &__title {
            margin-left: 1.5rem;
        }

        &__content {
            margin-top: calc(40vh - 4.6875rem);
        }

        &__logo {
            width: 6.25rem;
        }
    }
}

@media screen and (min-width: vars.$break-large) {
    .introduction {
        width: 35.625rem;

        &__title {
            margin-left: 1.75rem;
        }

        &__logo {
            width: 7.5rem;
        }
    }
}
