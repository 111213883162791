@use "../../../core/styles/variables.scss" as vars;

.about {
    &__content {
        max-width: vars.$break-large;
        margin: 0 auto;
        text-align: justify;

        p {
            color: vars.$main-text-color;
        }
    }
}

.indent {
    margin-bottom: 1rem;
    text-indent: 1rem;
}

.dark-text-span {
    color: vars.$dark-text-color;
}
