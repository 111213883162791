@use "../../../core/styles/variables.scss" as vars;

.project {
    $block: &;
    background-color: vars.$dark-background-color;
    overflow: hidden;
    position: relative;

    &__screen-shot {
        display: block;
        height: auto;
        max-width: 100%;
        transition: 0.25s ease;
        width: auto;
    }

    &__overlay {
        left: 50%;
        opacity: 0;
        position: absolute;
        text-align: center;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: 0.25s ease;
        width: 90%;

        p {
            color: vars.$light-text-color;
        }
    }

    &__title {
        color: vars.$accent-color;
        margin-top: 0.25rem;
        font-size: 1.75rem;
    }

    &__technologies {
        bottom: 0;
        position: absolute;
        right: 0.5rem;
    }

    &__technology {
        filter: drop-shadow(
            0.125rem 0.125rem 0.25rem vars.$dark-bg-shadow-color
        );
        height: 1.875rem;
        transition: 0.25s ease;
    }

    &__github {
        bottom: 0.25rem;
        color: vars.$light-text-color;
        filter: drop-shadow(
            0.125rem 0.125rem 0.25rem vars.$dark-bg-shadow-color
        );
        left: 0.5rem;
        position: absolute;
        font-size: 1.875rem;
        line-height: 1.875rem;
    }

    &:hover {
        #{$block}__screen-shot {
            transform: scale(1.1);
        }

        #{$block}__screen-shot,
        #{$block}__technology {
            opacity: 0.1;
        }

        #{$block}__overlay {
            opacity: 1;
        }
    }
}

@media screen and (min-width: vars.$break-small) {
    .project {
        &__screen-shot {
            max-width: 20rem;
        }

        &__title {
            font-size: 1.5rem;
        }

        &__overlay p {
            font-size: 0.9rem;
        }
    }
}
