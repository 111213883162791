@use "../../../core/styles/variables.scss" as vars;

.projects {
    .nav-links {
        display: block;
        font-size: 1.25rem;
        padding-bottom: 1.25rem;
        margin: 0 auto;
        text-align: center;
    }

    &__list {
        max-width: 62rem;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 1rem;
    }
}

@media screen and (min-width: vars.$break-small) {
    .projects {
        .nav-links {
            width: 32rem;
        }
    }
}
