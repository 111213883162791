@use "../../core/styles/variables.scss" as vars;

.footer {
    margin: 0.625rem 0 1.25rem;
    text-align: center;
    width: 100%;

    &__back-to-top-button {
        background-color: vars.$nav-color;
        font-family: vars.$mono-font-stack;
        font-size: 1.5rem;

        &:hover {
            color: vars.$light-text-color;
            transition: 0.25s ease;
        }
    }

    &__text {
        margin: 1.25rem auto 0;
        text-align: center;
        color: vars.$main-text-color;
    }
}

@media screen and (min-width: vars.$break-small) {
    .footer {
        &__back-to-top-button {
            display: none;
        }

        &__text {
            font-size: 0.8rem;
        }
    }
}
